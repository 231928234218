import React from 'react'
import LayoutStatic from '../../../components/LayoutStatic';
import { Link } from 'gatsby';

const style = {
  card: {
    width: '18rem',
    margin: 'auto',
    marginTop: '20px',
  },
  button: {
    marginTop: '10px',
    marginRight: '10px',
    width: '100%'
  }
}

const OldPage = () => {
  return (
    <LayoutStatic>
      <div className="card" style={style.card} >
        <img src="/img/Old.jpg" className="card-img-top bootstrap-card-img-top" alt="..." />
        <div className="card-body">
          <h5 className="card-title">ประเมินสุขภาพ ผู้สูงอายุ</h5>
          <p className="card-text">เมื่ออายุมากขึ้น ก็ต้องใส่ใจมากขึ้น คนที่ดูแลเราได้ดีที่สุด ก็คือ ตัวเราเอง ประเมิน ฟรี!!</p>
          {/* <p className="card-text" style={{ color: 'red' }}>(กำลังอยู่ในขั้นตอนการพัฒนา)</p> */}
          <Link to="/questionnaire?questionnaireId=41" className="btn btn-primary" style={style.button}>แบบคัดกรองโรคสมองเสื่อม</Link>
          <Link to="/questionnaire?questionnaireId=42" className="btn btn-primary" style={style.button}>ประเมินพฤติกรรมสุขภาพในชีวิตประจำวัน</Link>
          <Link to="/questionnaire?questionnaireId=43" className="btn btn-primary" style={style.button}>แบบประเมินภาวะซึมเศร้า 9 คำถาม</Link>
          <Link to="/questionnaire?questionnaireId=44" className="btn btn-primary" style={style.button}>แบบคัดดรองภาวะกลืนลำบากในผู้สูงอายุ</Link>
          <Link to="/questionnaire?questionnaireId=45" className="btn btn-primary" style={style.button}>แบบคัดกรองสุขภาวะทางตา</Link>
          <Link to="/questionnaire?questionnaireId=46" className="btn btn-primary" style={style.button}>แบบคัดกรองภาวะการรู้คิดในผู้สูงอายุ</Link>
          <Link to="/questionnaire?questionnaireId=48" className="btn btn-primary" style={style.button}>คัดกรองจำแนกระดับโรคความดันโลหิตสูง</Link>
          
          {/* <Link to="#" className="btn btn-primary" style={style.button}>ตรวจความเสี่ยง อัลไซม์เมอร์</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>กระดูก และ ข้อ</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>เบาหวาน</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>โรค พาคินสัน</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>โรค ไทรอยด์</Link> */}
          <p></p>
          {/* <p className="card-text" style={{ color: 'blue' }}>(แบบคัดกรองอื่นๆ..กำลังพัฒนา)</p> */}
        </div>
      </div>
    </LayoutStatic>
  );
}

export default OldPage;

